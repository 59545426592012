import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Toolbar } from '@mui/material';
import { Box } from '@mui/system';

import { AppHeader } from './components/shared/AppHeader/AppHeader';
import { AppSidebar } from './components/shared/AppSidebar/AppSidebar';
import TopAlert from './components/topAlert/TopAlert';
import { APP_ROUTES } from './constants/AppRoutes';

const RouteLayout = () => {
  const location = useLocation();

  const allRoutes = Object.values(APP_ROUTES).map((route) => route.ROUTE);
  const purePath = location.pathname.slice(1);
  const showSidebar = allRoutes.some((route) => purePath.startsWith(route));

  return (
    <>
      {showSidebar ? (
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <AppHeader />
          <AppSidebar />
          <Box component="main" sx={{ flexGrow: 1 }}>
            <Toolbar />
            <Outlet />
            <TopAlert />
          </Box>
        </Box>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default RouteLayout;
