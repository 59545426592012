import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

import IconCart from '../../../assets/images/icon-cart.svg';
import IconCartHover from '../../../assets/images/icon-cart-hover.svg';
import IconDashboard from '../../../assets/images/icon-dashboard.svg';
import IconDashboardHover from '../../../assets/images/icon-dashboard-hover.svg';
import IconMyInfo from '../../../assets/images/icon-my-info.svg';
import IconMyInfoHover from '../../../assets/images/icon-my-info-hover.svg';
import IconProductHover from '../../../assets/images/productIconHover.svg';
import IconProduct from '../../../assets/images/productSide.svg';
import IconSalesHover from '../../../assets/images/saleIconHover.svg';
import IconSales from '../../../assets/images/salesIcon.svg';
import StarIcon from '../../../assets/images/starIcon.svg';
import StarIconHover from '../../../assets/images/startHover.svg';
import { APP_ROUTES } from '../../../constants/AppRoutes';

import '../css/sidebar.min.css';

const drawerWidth: number = 280;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => {
  return {
    '& .MuiDrawer-paper': {
      background: 'white',
      boxShadow: 'none',
      borderLeft: 'none',
      position: 'relative',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      overflowX: 'hidden',
      ...(!open
        ? {
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            // width: "121px !important",
          }
        : {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
    },
  };
});

export const AppSidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [openDropdownSales, setOpenDropdownSales] = useState(false);
  const [openDropdownProducts, setOpenDropdownProducts] = useState(false);

  const openDropdownSalesHandler = () => {
    setIsSidebarOpen(true);
    setOpenDropdownSales(!openDropdownSales);
    setOpenDropdownProducts(false);
  };
  const openDropdownProductsHandler = () => {
    setIsSidebarOpen(true);
    setOpenDropdownSales(false);
    setOpenDropdownProducts(!openDropdownProducts);
  };

  const collapseAllDropdowns = () => {
    setIsSidebarOpen(true);
    setOpenDropdownSales(false);
    setOpenDropdownProducts(false);
  };

  return (
    // <Box component="nav">
    <Drawer className={`app-sidebar ${isSidebarOpen ? 'open' : ''}`} variant="permanent" open={isSidebarOpen}>
      <Toolbar />
      <List component="nav">
        {/* Menu Item */}
        <ListItem disablePadding>
          <NavLink to={APP_ROUTES.DASHBOARD.ROUTE} onClick={collapseAllDropdowns}>
            <ListItemIcon>
              <img src={IconDashboard} alt="Icon" />
              <img src={IconDashboardHover} alt="Icon" />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </NavLink>
        </ListItem>
        {/* Menu Item */}
        <ListItem disablePadding>
          <ListItemButton
            onClick={openDropdownSalesHandler}
            className={window.location.href.indexOf(APP_ROUTES.SALES.ROUTE) > -1 ? 'active' : ''}
          >
            <ListItemIcon>
              <img src={IconSales} alt="Icon" />
              <img src={IconSalesHover} alt="Icon" />
            </ListItemIcon>
            <ListItemText primary="Sales" />
            {openDropdownSales ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openDropdownSales} timeout="auto" unmountOnExit>
          <NavLink to={APP_ROUTES.SALES_STATISTICS.ROUTE}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Sales Statistics" />
              </ListItemButton>
            </ListItem>
          </NavLink>
          <NavLink to={APP_ROUTES.SALES_HISTORY.ROUTE}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Sales History" />
              </ListItemButton>
            </ListItem>
          </NavLink>
        </Collapse>
        {/* Menu Item */}
        <ListItem disablePadding>
          <NavLink
            to={APP_ROUTES.ORDER_LIST.ROUTE}
            className={window.location.href.indexOf(APP_ROUTES.ORDER.ROUTE) > -1 ? 'active' : ''}
            onClick={collapseAllDropdowns}
          >
            <ListItemIcon>
              <img src={IconCart} alt="Icon" />
              <img src={IconCartHover} alt="Icon" />
            </ListItemIcon>
            <ListItemText primary="Order" />
          </NavLink>
        </ListItem>
        {/* Menu Item */}
        <ListItem disablePadding>
          <NavLink to={APP_ROUTES.ORGANIZATION_LIST.ROUTE} onClick={collapseAllDropdowns}>
            <ListItemIcon>
              <img src={IconMyInfo} alt="Icon" />
              <img src={IconMyInfoHover} alt="Icon" />
            </ListItemIcon>
            <ListItemText primary="Organization" />
          </NavLink>
        </ListItem>
        {/* Menu Item */}
        <ListItem disablePadding>
          <NavLink to={APP_ROUTES.RATING.ROUTE} onClick={collapseAllDropdowns}>
            <ListItemIcon>
              <img src={StarIcon} alt="Icon" />
              <img src={StarIconHover} alt="Icon" />

              {/* <img src={IconMyInfoHover} alt="Icon" /> */}
            </ListItemIcon>
            <ListItemText primary="Rating" />
          </NavLink>
        </ListItem>
        {/* Menu Item */}
        {/* Menu Item */}
        <ListItem disablePadding>
          <ListItemButton
            onClick={openDropdownProductsHandler}
            className={window.location.href.indexOf(APP_ROUTES.PRODUCTS.ROUTE) > -1 ? 'active' : ''}
          >
            <ListItemIcon>
              <img src={IconProduct} alt="Icon" />
              <img src={IconProductHover} alt="Icon" />
            </ListItemIcon>
            <ListItemText primary="Products" />
            {openDropdownProducts ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openDropdownProducts} timeout="auto" unmountOnExit>
          <NavLink to={APP_ROUTES.PRODUCTS_LIST.ROUTE}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Product List" />
              </ListItemButton>
            </ListItem>
          </NavLink>
          <NavLink to={APP_ROUTES.ADDITIONAL_OPTIONS.ROUTE}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Additional Option" />
              </ListItemButton>
            </ListItem>
          </NavLink>
          <NavLink to={APP_ROUTES.PRODUCTS_REMAKE.ROUTE}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Remake" />
              </ListItemButton>
            </ListItem>
          </NavLink>
        </Collapse>
        {/* Menu Item */}
      </List>
    </Drawer>
    // </Box>
  );
};
