import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';

import '../css/dialog.min.css';

interface IPropsDialog {
  iconClose?: boolean;
  open: boolean;
  title: any;
  variant?: any;
  width?: string;
  customStyle?: any;
  handleClose: any;
  children?: React.ReactNode;
  isDisabled?: boolean;
  hideCloseViaClickOnbackDrop?: boolean;
  btnActionText?: string;
  btnAction?: () => void;
  btnDisabled?: boolean;
  btnType?: 'submit';
  formId?: string;
}

export default function CustomDialog({
  title,
  width = '100%',
  iconClose = false,
  variant = 'h6',
  customStyle,
  children,
  open,
  handleClose,
  hideCloseViaClickOnbackDrop = false,
  btnActionText,
  btnAction,
  btnDisabled,
  btnType,
  formId,
}: Readonly<IPropsDialog>) {
  return (
    <Dialog
      open={open}
      onClose={!hideCloseViaClickOnbackDrop ? handleClose : () => {}}
      sx={{
        '& .MuiDialog-paper': {
          width,
          customStyle,
        },
      }}
    >
      <DialogTitle sx={{ p: '24px', pr: '12px' }}>
        <Typography variant={variant} fontWeight={700}>
          {title}
        </Typography>
      </DialogTitle>
      {iconClose && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 15,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant={'outlined'} onClick={handleClose} color="primary" size={'medium'}>
          Cancel
        </Button>
        {btnActionText && (
          <Button
            onClick={btnAction}
            variant={'contained'}
            color="primary"
            size={'medium'}
            type={btnType}
            form={formId}
            disabled={btnDisabled ?? false}
          >
            {btnActionText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
