import * as React from 'react';
import { Avatar, IconButton, Popover, Stack, Tooltip, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import { UserGetResponse } from '../../../../client';
import { logoutBO, useUserQuery } from '../../../../recoil/auth/useAuth';

import '../css/user.min.css';

// let user = { name: "John Doe", email: "John@imagoworks.ai" };

export const HeaderUser = () => {
  const { data } = useUserQuery();
  const userData = data?.data as UserGetResponse;

  const [showChangePasswordDlg, setShowChangePasswordDlg] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const signOutHandler = async () => {
    await logoutBO();
    window.location.replace(
      `${process.env.REACT_APP_ACCOUNTS_CLIENT_URL}/user?redirect_url=${process.env.REACT_APP_CONNECT_BO_CLIENT_URL}`,
    );
  };

  return (
    <>
      <IconButton color="inherit" aria-describedby={id} onClick={handleClick}>
        <Tooltip title="Account" arrow>
          <Avatar src={''} />
        </Tooltip>
      </IconButton>
      <Popover
        className="popover-user"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ '& .MuiPaper-root': { padding: '0 !important', minWidth: '258px' } }}
      >
        <Stack direction="column">
          <Stack direction="column" sx={{ padding: '16px' }}>
            <Typography variant="subtitle2">{`${userData?.name?.firstName ?? ''} ${userData?.name?.lastName ?? ''}`}</Typography>
            <Typography variant="body2" color="text.secondary">
              {userData?.email ?? ''}
            </Typography>
          </Stack>
          <Stack direction="column" gap="4px" sx={{ padding: '8px' }}>
            <MenuItem
              key="changePassword"
              onClick={() =>
                window.location.replace(`${process.env.REACT_APP_CLOUD_CLIENT_URL as string}/myinfo/account`)
              }
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '22px',
                padding: '6px !important',
                borderRadius: '6px',
              }}
            >
              Profile
            </MenuItem>
            <MenuItem
              key="signOut"
              onClick={signOutHandler}
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '22px',
                padding: '6px !important',
                borderRadius: '6px',
              }}
            >
              Sign out
            </MenuItem>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};
