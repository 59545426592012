import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ColorPair, optionGroup } from '../../../client';
import CustomDialog from '../../../components/dialog/CustomDialog';
import { useProductOptions } from '../../../recoil/products/useProduct';

const OPTION_GROUP = [
  { name: 'PROSTHESIS' },
  { name: 'METHOD' },
  { name: 'MATERIAL' },
  { name: 'SHAPE' },
  // { name: 'PRICE_UNIT' },
  // { name: 'COLOR_CODE' },
];

const PALETTE_OPTION = [
  { name: 'Dark Blue', stroke: '#0D3B66', fill: '#E5F2F9' },
  { name: 'Brick Red', stroke: '#C0392B', fill: '#F9EBEA' },
  { name: 'Forest Green', stroke: '#2E7D32', fill: '#E8F5E9' },
  { name: 'Tangerine', stroke: '#EF6C00', fill: '#FFF3E0' },
  { name: 'Purple', stroke: '#0277BD', fill: '#E1F5FE' },
  { name: 'Maroon', stroke: '#662222', fill: '#F9E5E5' },
  { name: 'Sky Blue', stroke: '#8E24AA', fill: '#F3E5F5' },
  { name: 'Coral', stroke: '#E91E63', fill: '#FFF0F0' },
  { name: 'Deep Purple', stroke: '#3949AB', fill: '#E8EAF6' },
  { name: 'Golden Yellow', stroke: '#F9A825', fill: '#FFFDE7' },
  { name: 'Dark Lavender', stroke: '#5E35B1', fill: '#EDE7F6' },
  { name: 'Light Green', stroke: '#7CB342', fill: '#F1F8E9' },
  { name: 'Dark Gray', stroke: '#607D8B', fill: '#ECEFF1' },
  { name: 'Olive Green', stroke: '#9E9D24', fill: '#F9FBE7' },
  { name: 'Slate Gray', stroke: '#424242', fill: '#F3F3F3' },
  { name: 'Bright Pink', stroke: '#D84315', fill: '#FBE9E7' },
];

interface ProductOptionEditDialogProps {
  isOpen: boolean;
  handleClose: () => void;
}

export default function ProductOptionEditDialog({ isOpen, handleClose }: ProductOptionEditDialogProps) {
  const [selectedProductOption, setSelectedProductOption] = useState<optionGroup>('PROSTHESIS');

  const [isAddMode, setIsAddMode] = useState(false);
  const [typedOption, setTypedOption] = useState('');
  const [selectedColor, setSelectedColor] = useState<ColorPair>({ fill: '', stroke: '' });

  console.log(isAddMode);
  // useSuspenseQuery 사용할 것.
  const { data } = useProductOptions(selectedProductOption);
  console.log(data);
  console.log(selectedProductOption);

  const handleOptionClick = (e: React.SyntheticEvent<Element, Event>, newValue: string) => {
    console.log(newValue);
    // const { value } = e.target as HTMLButtonElement;
    setSelectedProductOption(newValue as optionGroup);
    setIsAddMode(false);
    setTypedOption('');
    setSelectedColor({ fill: '', stroke: '' });
  };
  const handleAddModeClick = () => {
    setIsAddMode((prev) => !prev);
  };

  const handleTypedOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    setTypedOption(value);
  };

  const handleSelectedColorChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    const { stroke, fill } = PALETTE_OPTION.find((color) => color.name === value) as ColorPair;
    setSelectedColor({ stroke, fill });
  };

  const selectedColorName = PALETTE_OPTION.find(
    (color) => color.stroke === selectedColor.stroke && color.fill === selectedColor.fill,
  )?.name;

  return (
    <CustomDialog
      open={isOpen}
      title={'Product Options'}
      handleClose={() => {
        handleClose();
        setIsAddMode(false);
      }}
      btnAction={() => {
        handleClose();
        setIsAddMode(false);
      }}
      btnActionText="Apply"
    >
      <Tabs onChange={handleOptionClick} value={selectedProductOption} sx={{ mb: '20px' }}>
        {OPTION_GROUP.map(({ name }) => (
          <Tab key={name} label={name} value={name} />
        ))}
      </Tabs>

      <Stack direction="row" sx={{ width: '100%' }} justifyContent="space-between">
        <Button variant="outlined" size="small" disabled={isAddMode} onClick={handleAddModeClick}>
          <AddIcon />
          Add new
        </Button>

        {isAddMode && (
          <Stack direction="row" gap="6px">
            {/* Add Value*/}
            <TextField value={typedOption} onChange={handleTypedOption} size="small" />

            {/* Select */}
            {selectedProductOption === 'PROSTHESIS' && (
              <Box>
                <FormControl fullWidth size="small">
                  <InputLabel id="select-color" size="normal">
                    Color
                  </InputLabel>
                  <Select
                    labelId="select-color"
                    value={selectedColorName}
                    size="small"
                    label="Colour"
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleSelectedColorChange}
                    sx={{ minWidth: '100%' }}
                  >
                    {PALETTE_OPTION.map(({ name, fill, stroke }) => {
                      return (
                        <MenuItem
                          key={name}
                          value={name}
                          sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}
                        >
                          <ColorBox fill={fill} stroke={stroke} />
                          <Typography>{name}</Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            )}
            <Button sx={{}} size="small" variant="contained">
              Add
            </Button>
          </Stack>
        )}
      </Stack>

      <Divider sx={{ margin: '20px 0' }} />

      <Stack sx={{ height: '284px', overflowY: 'auto', padding: '8px 4px', marginBottom: '20px' }} gap="4px">
        {data?.map((option) => (
          <Stack
            key={option.value}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: '100%',
              minHeight: '62px',
              padding: '0 12px',
              backgroundColor: '',
              '&:hover': { backgroundColor: '#919EAB14' },
            }}
          >
            <Typography variant="subtitle1">{option.value}</Typography>
            {/* Color here */}
            {/* TODO: color 값 가져오기 */}
            {/*{selectedProductOption === 'PROSTHESIS' && <ColorBox fill={fill} stroke={stroke} />}*/}
            <Button variant="contained" color="error" size="small">
              Delete
            </Button>
          </Stack>
        ))}
      </Stack>
    </CustomDialog>
  );
}

const ColorBox = (color: ColorPair) => {
  return (
    <Box border={color.stroke} sx={{ marginRight: '9px' }}>
      <Box sx={{ width: '22px', height: '22px', backgroundColor: color.stroke, borderRadius: '8px' }} />
    </Box>
  );
};
