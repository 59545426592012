import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Chip, Stack, TextField, Typography } from '@mui/material';
import { isAxiosError } from 'axios';
import humanizeString from 'humanize-string';

import { ColorPair, optionGroup, ProductOption } from '../../../client';
import CustomDialog from '../../../components/dialog/CustomDialog';
import { useDialogue } from '../../../constants/helperHooks/useDialogue';
import { useProductOptionDelete, useProductOptionMutation } from '../../../recoil/products/useProduct';
import ColorSelector, { DEFAULT_COLOR_SET } from './ColorDialog';

type EditMode = 'DELETE' | 'ADD' | 'MODERATE';

interface IEditOptionDialogProps {
  open: boolean;
  onCloseDialogue: () => void;
  selectedEditOption: optionGroup;
  userSelectedEditOptionList: Array<ProductOption>;
  selectedColor: ColorPair;
  onColorChange: (color: ColorPair) => void;
}

export default function EditOptionDialog({
  open,
  onCloseDialogue,
  selectedEditOption,
  userSelectedEditOptionList,
  selectedColor,
  onColorChange,
}: Readonly<IEditOptionDialogProps>) {
  const { mutateAsync } = useProductOptionMutation();
  const { mutateAsync: deleteProductOption } = useProductOptionDelete();
  const [editMode, setEditMode] = useState<EditMode>('MODERATE');
  const [userTypedValue, setUserTypedValue] = useState('');

  console.log(userSelectedEditOptionList);

  // unable to delete dialog
  const {
    open: isUnableToDeleteOpen,
    handleOpenDialogue: unableToDeleteDialogOpen,
    handleCloseDialogue: unableToDeleteDialogClose,
  } = useDialogue();

  // mode
  const handleModeChange = (mode: EditMode) => {
    setEditMode(mode);
  };

  const isProsthesisEdit = selectedEditOption === 'PROSTHESIS';

  const isColorNotSelected = selectedColor.stroke === '' || selectedColor.fill === '';

  const handleAddOptionClick = async () => {
    const res = await mutateAsync({
      optionGroup: selectedEditOption,
      value: userTypedValue,
      colorCode: selectedColor,
    });
    if (res.success) {
      setUserTypedValue('');
    }
  };

  const handleDeleteClick = async (id: string) => {
    try {
      await deleteProductOption(id);
    } catch (e) {
      if (isAxiosError(e) && e.response && e.response.data.errorCode === 'CONNECT:PRODUCT_OPTION_IS_USED') {
        unableToDeleteDialogOpen();
      }
    }
  };

  const handleCancelClick = () => {
    if (editMode !== 'MODERATE') {
      setEditMode('MODERATE');
    } else {
      setEditMode('MODERATE');
      onCloseDialogue();
    }
    setUserTypedValue('');
    onColorChange(DEFAULT_COLOR_SET);
  };

  const isAddButtonDisabled =
    selectedEditOption === 'PROSTHESIS' ? userTypedValue === '' || isColorNotSelected : userTypedValue === '';

  // useEffect(() => {}, [])

  return (
    <>
      <CustomDialog
        title={`Edit ${humanizeString(selectedEditOption)}`}
        open={open}
        handleClose={handleCancelClick}
        btnActionText="Apply changes"
        // btnDisabled={!!selectedColor}
        btnAction={() => {}}
        // dialogActions
      >
        <Stack gap="24px" sx={{ paddingBottom: '24px' }}>
          <Stack direction="row" alignItems="center" flexWrap="wrap" gap="8px">
            {userSelectedEditOptionList?.map((item) => {
              return (
                <Chip
                  key={item.id}
                  icon={
                    editMode === 'DELETE' ? (
                      <CloseIcon
                        sx={{
                          ml: '-20px !important',
                          mr: '5px !important',
                          background: '#c6c8cb',
                          color: 'white !important',
                          p: 0.1,
                          fontSize: 15,
                          borderRadius: 100,
                          cursor: 'pointer',
                        }}
                        // TODO: 타입 수정 후 'as string' 삭제
                        onClick={() => handleDeleteClick(item.id)}
                      />
                    ) : undefined
                  }
                  sx={{
                    gap: 2,
                    bgcolor: '#ffffff',
                    border: '1px solid #c6c8cb',
                    // position: "absolute",
                    // left: "50%",
                    // transform: "translateX(-50%)",
                    // bottom: -18,
                    zIndex: 1,
                    flexDirection: 'row-reverse',
                  }}
                  label={item.value}
                />
              );
            })}
          </Stack>

          {editMode === 'ADD' && (
            <Stack gap="24px">
              <Stack direction="row" gap="12px" justifyContent="space-between" alignItems="center">
                <TextField
                  onChange={(e) => setUserTypedValue(e.target.value)}
                  value={userTypedValue}
                  variant="outlined"
                  size="small"
                  sx={{ width: '100%' }}
                />
                <Button onClick={handleAddOptionClick} disabled={isAddButtonDisabled} variant="outlined">
                  <AddIcon /> Add
                </Button>
              </Stack>

              {isProsthesisEdit && <ColorSelector selectedColor={selectedColor} onColorChange={onColorChange} />}
            </Stack>
          )}

          {/* Delete / Add buttons */}
          {editMode === 'MODERATE' && (
            <Stack sx={{ width: '100%' }} justifyContent="space-between" alignItems="center" gap="8px">
              <Stack
                sx={{ width: '100%', height: '40px' }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap="8px"
              >
                <Button
                  onClick={() => handleModeChange('DELETE')}
                  sx={{ width: '100%', height: '40px' }}
                  variant="outlined"
                >
                  <DeleteIcon />
                  Delete
                </Button>
                <Button
                  onClick={() => handleModeChange('ADD')}
                  sx={{ width: '100%', height: '40px' }}
                  variant="outlined"
                >
                  <AddIcon /> Add
                </Button>
              </Stack>
            </Stack>
          )}
        </Stack>
      </CustomDialog>

      <DeleteUnableDialog open={isUnableToDeleteOpen} onClose={unableToDeleteDialogClose} />
    </>
  );
}

interface DeleteUnableDialogProps {
  open: boolean;
  onClose: () => void;
}
function DeleteUnableDialog({ open, onClose }: DeleteUnableDialogProps) {
  return (
    <CustomDialog open={open} title="Unable to delete" handleClose={onClose}>
      <Box>
        <Typography>This item option is used.</Typography>
      </Box>
    </CustomDialog>
  );
}
