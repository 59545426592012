export const APP_ROUTES = {
  LOGIN: {
    ROUTE: '/auth/login',
  },
  DASHBOARD: {
    ROUTE: 'dashboard',
  },
  SALES: {
    ROUTE: 'sales',
  },
  SALES_STATISTICS: {
    ROUTE: 'sales/statistics',
  },
  SALES_HISTORY: {
    ROUTE: 'sales/history',
  },
  ORDER: {
    ROUTE: 'order',
  },
  ORDER_LIST: {
    ROUTE: 'order/list',
  },
  ORDER_DETAIL: {
    ROUTE: 'order/detail',
  },
  ORGANIZATION_LIST: {
    ROUTE: 'organization/list',
  },
  ORGANIZATION_DETAIL: {
    ROUTE: 'organization/detail',
  },
  RATING: {
    ROUTE: 'rating',
  },
  PRODUCTS: {
    ROUTE: 'products',
  },
  PRODUCTS_LIST: {
    ROUTE: 'products/list',
  },
  PRODUCTS_LAB_SUPPLY_LIST: {
    ROUTE: 'products/lab-supply-list',
  },
  ADDITIONAL_OPTIONS: {
    ROUTE: 'additional/options',
  },
  PRODUCTS_REMAKE: {
    ROUTE: 'products/remake',
  },
};
