import { useEffect } from 'react';

import { useCreateChannel, UseCreateChannelProps } from '../../../../hooks/chat/sendbirdSDK';
import { useOrderAddChat } from '../../../../recoil/order/useOrder';

interface GroupChannelRetryProps {
  chattingChannelUrl: string | undefined;
  retryChannelInfo: UseCreateChannelProps;
  children: React.ReactNode;
}
export default function GroupChannelRetry({ chattingChannelUrl, retryChannelInfo, children }: GroupChannelRetryProps) {
  // const { mutateAsync: addChatUrlAsync } = useOrderAddChat(retryChannelInfo.orderId);
  const { mutateAsync: createChannelAsync } = useCreateChannel();

  // TODO
  // 1. orderId 로 채널 있는지 먼저확인 (?)
  // 2. 없으면 채널 생성 (v)
  // 3. PATCH 로 DB 에 정보 추가해주는 작업 필요. -> 백오피스용 api 추가 필요!
  useEffect(() => {
    const createChannel = async () => {
      try {
        const channel = await createChannelAsync(retryChannelInfo);
        if (!channel) return;

        // API 필요 !! <- 일단 채널 생성하고 id 넣어놓아서 나오게 해놨음. -> 필요한가?
        // return await addChatUrlAsync({ chattingChannelUrl: channel.url });
      } catch (err) {
        console.log(err);
      }
    };

    if (chattingChannelUrl === '-1' || !chattingChannelUrl) {
      createChannel().then((res) => console.log(res));
    }
  }, [chattingChannelUrl]);

  return children;
}
